var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.data } },
            [
              _c("el-table-column", {
                attrs: { prop: "standardCode", label: "标准编号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "purchaseNo", label: "采购订单号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "bomNo", label: "物料编号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "productName", label: "产品名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "sampleSize", label: "样本大小" }
              }),
              _c("el-table-column", {
                attrs: { prop: "colour", label: "颜色" }
              }),
              _c("el-table-column", {
                attrs: { prop: "parameterCode", label: "参数编号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "realityMaterial", label: "合格范围" }
              }),
              _c("el-table-column", {
                attrs: { prop: "inspectionMaterial", label: "检验值" }
              }),
              _c("el-table-column", {
                attrs: { prop: "evaluate", label: "评价" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "complianceWithStandards",
                  label: "是否符合标准"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "block",
              staticStyle: { float: "right", margin: "30px" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "block",
                  staticStyle: { float: "right", margin: "30px" }
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.page.currentPage,
                      "page-sizes": [10, 20, 50, 40, 50, 100],
                      "page-size": _vm.page.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.page.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "right", padding: "50px 0" } },
                [
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v(_vm._s(_vm.$t("Cancel")))
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }