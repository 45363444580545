import request from '@/router/axios';
import {businessUrl} from "../config/env";

//新增
export const add = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/insertMaterialFeedback",
        method: "post",
        data: {
            ...row
        }
    })
}

export const  list = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/selectMaterialFeedback",
        method: "post",
        data: {
            ...row
        }
    })
}

//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "qualityFeedback/delete",
        method: "delete",
        params: {
            id
        }
    })
}

//查看
export const chakan = (row) => {
    return request({
        url : businessUrl + "qualityFeedback/selectByStandardCode",
        method: "post",
        data: {
            ...row
        }
    })
}